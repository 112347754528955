<template>
  <main class="festival_order_complete">
    <section class="section_header">
      <h1>스탬프 팝 신청완료</h1>
      <div class="product_header_contents">
        <div>
          <span>1</span>
          <p></p>
        </div>
        <div>
          <span>2</span>
          <p></p>
        </div>
        <div>
          <span>3</span>
          <p>스탬프 팝 신청완료</p>
        </div>
      </div>
    </section>
    <section class="order_complete_body">
      <h1>
        스탬프 팝 <span>신</span><span>청</span><span>완</span><span>료</span>
      </h1>
      <div class="text_box">
        <p>축제 자료 등록이 정상 처리되었습니다.</p>
        <p>
          스탬프 팝 신청 절차는 모두 완료되었으며,<br class="br" />
          업로드하신 자료는 관리자가 확인한 후 플랫폼 셋팅을 진행합니다.
        </p>
        <div>
          <p>
            플랫폼 셋팅은 <span>약 2주가 소요</span>되며,<br class="br" />
            <span>MY스탬프 팝 > 결제내역/진행현황</span>
            메뉴에서 진행상황을 확인하실 수 있습니다.
          </p>
        </div>
        <div>
          <p>
            파일에 문제가 있거나 보완이 필요한 경우 회원정보에 저장된 전화번호
            또는 이메일로 연락드리겠습니다.
          </p>
        </div>
        <p>
          문의사항이 있는 경우 고객센터로 연락주시면 친절히 안내해드리겠습니다.
        </p>
      </div>
      <div class="email_chat">
        <p><span>이메일 : </span>festival@nextinnovation.kr</p>
        <p><span>채팅상담 : </span>우측 하단 문의하기 버튼 클릭</p>
      </div>
    </section>
    <div class="btn_center">
      <base-button type="primary" @click="$router.push({ name: 'Mypage' })"
        >MY스탬프 팝 바로가기</base-button
      >
    </div>
  </main>
</template>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  name: "MypageReference",
  components: { BaseButton },
  data() {
    return {};
  },
  created() {
    // 상품정보가 있는지 확인
    if (this.getBasket.order.id) {
      // 유지
      /**
       * 상품 아이디로 구해만 상품이 있는지 API 로 재확인 처리 할것
       */

      // 구매 정보 삭제
      this.basket({
        product: {
          id: null,
        },
      });
    } else {
      // 잘못된 접근, 구매 이력 없음, 메인 화면으로 이동
      this.$router.push({
        name: "FestivalOrderProduct",
      });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser", "getBasket"]),
  },
  methods: {
    ...mapMutations({
      basket: "basket",
    }),
  },
};
</script>
